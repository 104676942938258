<template>
  <div class="labcontent">
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn animate__slow"
      leave-active-class="animate__animated animate__fadeOut animate__slow"
    >
      <div class="block" v-if="notiondata.cover">
        <figure
          :class="'b-image-wrapper image is-3by1 labor_' + lab + '-labimage'"
        >
          <img
            :src="notiondata.cover.file.url"
            loading="lazy"
            :class="'has-ratio lab_' + lab + ' labimage'"
          />
          <img
            :src="notiondata.properties.Logo.files[0].file.url"
            loading="lazy"
            :class="'lablogo ' + lab"
          />
        </figure>
      </div>
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn animate__slow"
      leave-active-class="animate__animated animate__fadeOut animate__slow"
    >
      <div class="level is-mobile labitems" v-if="notiondata.properties">
        <div
          class="level-item has-text-centered labproperty mt-6 mb-5"
          v-if="notiondata.properties.Raum.rich_text.length != 0"
        >
          <img class="labicon" :src="require(`@/assets/Icons/sign.svg`)" />
          <p class="is-size-5 is-size-6-mobile mt-4 mb-0">Wo?</p>
          <p>{{ notiondata.properties.Raum.rich_text[0].plain_text }}</p>
        </div>
        <div
          class="level-item has-text-centered labproperty mt-6 mb-5"
          v-if="notiondata.properties.OpenLabZeit.rich_text.length != 0"
        >
          <img class="labicon" :src="require(`@/assets/Icons/open.svg`)" />
          <p class="is-size-5 is-size-6-mobile mt-4 mb-0">Open Lab</p>
          <p>{{ notiondata.properties.OpenLabZeit.rich_text[0].plain_text }}</p>
        </div>
        <div
          class="level-item has-text-centered labproperty mt-6 mb-5"
          v-if="notiondata.properties.HSFLlink.url"
        >
          <a :href="notiondata.properties.HSFLlink.url" target="_blank">
            <img
              class="labicon"
              :src="require(`@/assets/Icons/equipment.svg`)"
            />
            <p class="is-size-5 is-size-6-mobile mt-4 mb-0">mehr Infos</p>
            <p>auf hs-flensburg.de</p>
          </a>
        </div>
        <div
          class="level-item has-text-centered labproperty mt-6 mb-5"
          v-if="notiondata.properties.Website.url"
        >
          <a :href="notiondata.properties.Website.url" target="_blank">
            <img class="labicon" :src="require(`@/assets/Icons/website.svg`)" />
            <p class="is-size-5 is-size-6-mobile mt-4 mb-0">Website</p>
            <p>vom Labor</p>
          </a>
        </div>
        <div
          class="level-item has-text-centered labproperty mt-6 mb-5"
          v-if="notiondata.properties.Instagram.url"
        >
          <a :href="notiondata.properties.Instagram.url" target="_blank">
            <img
              class="labicon"
              :src="require(`@/assets/Icons/instagram.svg`)"
            />
            <p class="is-size-5 is-size-6-mobile mt-4 mb-0">Instagram</p>
            <p>vom Labor</p>
          </a>
        </div>
        <div
          class="level-item has-text-centered labproperty mt-6 mb-5"
          v-if="notiondata.properties.Facebook.url"
        >
          <a :href="notiondata.properties.Facebook.url" target="_blank">
            <img
              class="labicon"
              :src="require(`@/assets/Icons/facebook.svg`)"
            />
            <p class="is-size-5 is-size-6-mobile mt-4 mb-0">Facebook</p>
            <p>vom Labor</p>
          </a>
        </div>
      </div>
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn animate__slow"
      leave-active-class="animate__animated animate__fadeOut animate__slow"
    >
      <div class="block" v-if="notiondata.properties">
        <h1 class="is-size-1 is-size-2-mobile has-text-centered mt-6">
          {{ notiondata.properties.Name.title[0].plain_text }}
        </h1>
      </div>
    </transition>
  </div>
</template>

<script type="text/babel">
export default {
  name: "FullPageContent",
  components: {},
  data() {
    return {};
  },
  props: {
    notiondata: [Array, Object],
    lab: String,
  },
  methods: {},
};
</script>

<style lang="scss">
.lablogo {
  transform: scale(0.5);
  object-fit: contain !important;
  -webkit-filter: brightness(0) invert(1)
    drop-shadow(4px 4px 13px rgba(0, 0, 0, 0.75));
  filter: brightness(0) invert(1) drop-shadow(4px 4px 13px rgba(0, 0, 0, 0.75));
}

.lablogo.designlab {
  transform: scale(0.175);
  -webkit-filter: brightness(0) invert(1)
    drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.75));
  filter: brightness(0) invert(1) drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.75));
}

.lab_ideenreich,
.lab_softwarefactory,
.lab_oportuinityspace,
.lab_designlab {
  border-left: 10px solid $containerbuilding;
}
.lab_softwarefactory {
  border-right: 10px solid $cbuiling;
}
.lab_techshop,
.lab_e3lab {
  border-bottom: 10px solid $hbuilding;
}
.lab_usabilitylab {
  border-top: 10px solid $dbuilding;
}
.labitems {
  margin-top: 1rem;
  margin-bottom: 6rem !important;
  flex-wrap: wrap;
}
.labproperty {
  flex-direction: column;
  white-space: break-spaces;
  color: $background;
  a {
    color: $background;
  }
  a p {
    color: $background;
  }
  a:hover {
    color: $background;
  }
  a:hover p {
    color: $background;
  }
  a:hover img {
    opacity: 1;
  }
}
.labicon {
  width: 4.5rem;
  opacity: 0.6;
}
@media screen and (max-width: $tablet - 1px) {
  .lablogo.designlab {
    transform: scale(0.3);
  }
}

@media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
  .lablogo.designlab {
    transform: scale(0.2125);
  }
}
</style>
